import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import Layout from '@/components/layout'
import { EntryType, PageType, PageTypeProps, PropsData } from '@/types/site.interface'

const Page = dynamic(() => import('./page'))
const GalleryPage = dynamic(() => import('./gallery-page'))
const ArticlePage = dynamic(() => import('./article-page'))
const JobPage = dynamic(() => import('./job-page'))

const getComponent = (type: PageType, entryType: EntryType) => {
  switch (type) {
    case 'entry':
      switch (entryType) {
        case 'article':
          return ArticlePage
        default:
          return GalleryPage
      }
    case 'job':
      return JobPage
    case 'page':
    default:
      return Page
  }
}

const PageProxy: FC<PropsData<PageTypeProps>> = ({ data }) => {
  const { site, page, ldJson, seo, preview } = data

  const Component = getComponent(page?.type, page?.entryType)

  return (
    <Layout site={site} page={page} seo={seo} ldJson={ldJson} preview={preview}>
      <Component page={page} />
    </Layout>
  )
}

export default PageProxy
